import React from 'react';
import caricatura from '../images/caricatura.png';

const Itarare = () => {
  return (
    <div>
      <div className='row mt-5'>
        <div className='col text-center'>
          <img src={caricatura} alt='caricatura' />
        </div>
        <div className='col'>
          <p>
            Apparício Torelly, ou o "Barão de Itararé" como viria a ser
            conhecido, foi um jornalista e escritor gaúcho, tornando-se um dos
            mais emblemáticos pioneiros do humorismo político brasileiro.
          </p>
          <p>
            Em 1926 fundou o Semanário "A Manha", parodiando o jornal "A Manhã"
            onde trabalhava. A frase "Quem não chora, não mama", ao lado do
            título, já indicava seu conteúdo irônico e bem humorado.
          </p>
          <p>
            O título nobiliárquico ele próprio se auto-concedeu, homenageando a
            batalha de Itararé, a batalha que nunca houve. Na Revolução de 1930,
            anunciava-se uma sangrenta batalha que ocorreria entre as tropas
            fiéis ao presidente Washington Luís e as que vinham do Rio Grande do
            Sul, comandadas por Getúlio Vargas, na cidade de Itararé, bem na
            divisa entre o Paraná e São Paulo. Depois de acordos e aconchavos,
            acabou não ocorrendo nenhuma luta. Mais uma tirada genial de
            Torelly...
          </p>
          <p>
            Em 1949 e 1951, ele editou seus famosos "Al
            <span className='fw-bold'>manha</span>ques" parodiando os Almanaques
            então em moda. Meu pai possuia esses exemplares na sua biblioteca,
            cuja leitura me divertiu muito na adolescência. Este programa é uma
            coletânea de 143 frases pitorescas neles publicadas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Itarare;
