import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';

import './App.css';
import Frases from './components/Frases';
import Itarare from './components/Itarare';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='container'>
        <h1 className='display-3 text-center mt-3'>
          Adágios do Barão de Itararé
        </h1>
        <Itarare />
        <Frases />
      </div>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
