import React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import Frase from '../components/Frase';

const fetchFrases = async (page) => {
  const pageNo = parseInt(page) || 0;
  const res = await fetch(
    `https://itarare-adages.herokuapp.com/api/v1/frases/?page=${pageNo}&limit=5`
  );
  if (res.ok) {
    await new Promise((r) => setTimeout(r, 1000));
    return res.json();
  }
};

const Frases = () => {
  const queryClient = useQueryClient();

  const [page, setPage] = React.useState(1);

  const { status, data, error, isFetching, isPreviousData } = useQuery(
    ['frases', page],
    () => fetchFrases(page),
    { keepPreviousData: true, staleTime: 15000, cacheTime: 10000 }
  );

  React.useEffect(() => {
    if (data?.next) {
      queryClient.prefetchQuery(['frases', page + 1], () =>
        fetchFrases(page + 1)
      );
    }
  }, [data, page, queryClient]);

  return (
    <div>
      <h2>Frases</h2>
      <div>
        {/* <div className='mb-2'>
          <p className='badge bg-success fs-6'>Página atual: {page}</p>
        </div> */}
        <button
          className='btn btn-outline-primary btn-sm'
          onClick={() => setPage((old) => Math.max(old - 1, 1))}
          disabled={page === 1}
        >
          Anterior
        </button>
        <button
          className='btn btn-outline-primary btn-sm ms-1'
          onClick={() => {
            setPage((old) => (!data || data?.next ? old + 1 : old));
          }}
          disabled={isPreviousData || !data?.next}
        >
          Próxima
        </button>
        <label type='text' className='ms-5 fw-bold'>
          Página atual
        </label>
        <input
          className='ms-2 text-center'
          type='number'
          name='selPage'
          id='pageSel'
          min='1'
          max='29'
          value={page}
          onChange={(event) => {
            if (event.target.value === '') {
              event.target.value = page; // evita limpar o input
            }
            let pag = event.target.value;
            setPage(parseInt(pag, 10));
          }}
        />
        {status === 'loading' ? (
          <div>Loading...</div>
        ) : status === 'error' ? (
          <div>Error : {error.message}</div>
        ) : (
          <div>
            {data.results.map((frases) => (
              <div>
                <Frase key={frases.frase} frase={frases.frase} />
              </div>
            ))}
          </div>
        )}
      </div>
      {isFetching ? <span> Loading... </span> : null}{' '}
    </div>
  );
};

export default Frases;
